<template>
  <RoomLayout>
    <template #background-image>
      <img
        :data-src="require('@/assets/images/planet-ring.svg')"
        alt="planet"
      >
    </template>
    <template #content>
      <div class="flex flex-col mt-5">
        <p class="geminis text-space-green text-4xl text-center pb-3">
          {{ $t('games.room_join.title') }}
        </p>
        <TextFormInput
          :placeholder="$t('games.room_join.placeholder')"
          :type="'text'"
          :value="pin"
          field="pin"
          :wrapper-classname="'!bg-white w-full'"
          :input-classname="'!text-space-dark-blue bg-white w-full'"
          @input="onFieldChanged($event)"
        />
        <PrimaryButton
          :bg-color="'bg-white'"
          :classes="'self-end text-space-dark-blue mt-5 hover:text-white w-2/4'"
          :text="$t('games.room_join.submit')"
          @click="getRoom"
        />
      </div>
    </template>
  </RoomLayout>
</template>

<script>
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import {API} from "@/utils/api";
import {TOAST_DEFAULT} from "@/constants/toast/toastConstants";
import RoomLayout from "@/layouts/Room/RoomLayout.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import {ROUTE_ROOM_ACCOUNT, ROUTE_ROOM_CONNECTION} from "@/router";
import {ROOM_STATUS_ON} from "@/constants/rooms/RoomStatus";
import {UPDATE_ROOM_STATE} from "@/store/modules/userModule/actions";
import {GAME_CONFIGURATION} from "@/constants/games/GameStatusConstants";

export default {
  name: "RoomLink",
  components: {RoomLayout, TextFormInput, PrimaryButton},
  data() {
    return {
      pin: null,
      user: this.$store.getters.getUser
    };
  },
  methods: {
    async getRoom() {
      try {
        await API.get(`room`, { params: {pin: this.pin, status: ROOM_STATUS_ON} });
      } catch (error) {
        this.$toast.error(this.$i18n.t('room.connection.errors.not_found').toString(), TOAST_DEFAULT);
        return;
      }

      if (this.$store.getters.isNotConnectedOrIsGuest) {
        await this.$router.push((
          {
            name: ROUTE_ROOM_ACCOUNT.name,
            query: {pin: this.pin, redirect: ROUTE_ROOM_CONNECTION.name}
          }
        ));
        return;
      }

      await this.$store.dispatch(UPDATE_ROOM_STATE, GAME_CONFIGURATION);
      await this.$router.push({name: ROUTE_ROOM_CONNECTION.name, query: {pin: this.pin, redirect: ROUTE_ROOM_CONNECTION.name}});
    },
    onFieldChanged(value) {
      this.pin = value;
    },
  }
};
</script>

<style scoped>

</style>
