import { render, staticRenderFns } from "./RoomLink.vue?vue&type=template&id=232c96fe&scoped=true&"
import script from "./RoomLink.vue?vue&type=script&lang=js&"
export * from "./RoomLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232c96fe",
  null
  
)

export default component.exports