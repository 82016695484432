<template>
  <button
    ref="button"
    type="button"
    class="uppercase text-center rounded-full font-bold px-5 lg:px-12 py-3.5 cursor-pointer transition duration-150 btn-hover"
    :class="[classes, bgColor]"
    :disabled="loading || disabled"
    @click="$emit('click')"
  >
    <div class="flex items-center whitespace-nowrap justify-center">
      <slot name="icon" />
      <span class="flex-1">
        {{ text }}
      </span>
    </div>
  </button>
</template>

<script>
import {createRipple} from "@/utils/RippleUtil";

export default {
  name: "PrimaryButton",
  emits: ["click"],
  props: {
    disabled: Boolean,
    text: String,
    bgColor: String,
    classes: String
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    handleClick() {
      if (!this.disabled){
        createRipple(this.$refs.button);
      }
      if (!this.onClick || this.disabled){
        return;
      }
      this.loading = true;
      Promise.resolve(this.onClick())
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>

<style scoped>
button {
  position: relative;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  transition: background-color 0.1s ease-in;
  font-family: 'Raleway-extra-bold', sans-serif;
  box-shadow: 0 0 20px rgba(77, 42, 156, 0.43);
}

button:not(.transform):not([disabled]):active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 0 0 20px rgba(60, 32, 146, 0.24);
}

button:is([disabled]){
  @apply bg-gray-400 cursor-not-allowed;
}

button:hover {
  @apply bg-gray-600;
}

</style>
