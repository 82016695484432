<template>
  <div
    class="flex items-center text-white cursor-pointer font-raleway-extra-bold"
    :class="classes"
    @click="goBack"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clip-rule="evenodd"
      />
    </svg>
    {{ $t('common.backToCyberSpace') }}
  </div>
</template>

<script>
import {ROUTE_HOME} from "@/router";

export default {
  name: "BackToSpaceButton",
  props: {
    classes: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack(){
      this.$router.push(ROUTE_HOME.path);
    }
  }
};
</script>

<style scoped>
</style>
