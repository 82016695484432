<template>
  <div
    id="roomLayout"
    class="p-6 sm:px-10 md:px-20"
    v-lazy-container="{selector: 'img'}"
  >
    <SimpleTopBar />

    <div class="absolute w-2/5">
      <slot name="background-image" />
    </div>

    <div class="grid lg:grid-cols-2 justify-items-center md:justify-items-start sm:grid-cols-1 md:space-x-24 md:mt-16 2xl:mt-28 ">
      <div class="relative w-full flex flex-col items-end space-y-7">
        <BackToSpaceButton classes="pb-8" />
        <slot name="text-page">
          <p class="geminis text-5xl lg:text-6xl xl:text-8xl text-shadow text-right">
            WELCOME <br> all space <br> traveler !
          </p>
        </slot>
        <Socials :show-text="true"/>
      </div>

      <div class="z-10 w-full md:w-4/5 2xl:w-4/6 md:-mt-6 pb-6 md:pb-0">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
import BackToSpaceButton from "@/components/buttons/BackToSpaceButton.vue";
import SimpleTopBar from "@/layouts/partials/other-layout/SimpleTopBar";
import Socials from "@/components/Socials";

export default {
  name: 'RoomLayout',
  components: {Socials, SimpleTopBar, BackToSpaceButton}
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
