/**
 * Is used to create ripple effect on component click
 * @param container
 */
export const createRipple = (container) => {

  const circle = document.createElement("span");
  const diameter = Math.max(container.clientWidth, container.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - container.offsetLeft - radius}px`;
  circle.style.top = `${event.clientY - container.offsetTop - radius}px`;
  circle.classList.add("ripple");

  const ripple = container.querySelector(".ripple");

  if (ripple) {
    ripple.remove();
  }

  container.appendChild(circle);
};
