<template>
  <div>
    <ul class="flex w-full list-none font-black text-space-green p-0 space-x-12 invisible lg:visible">
      <li class="flex items-center" v-if="showText">
        <span>{{ $t('common.followUs').toUpperCase() }}</span>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/spacecodeapp/"
           target="_blank">
          <img
            width="50"
            alt="linkedin"
            :data-src="require('@/assets/images/socials/linkedin.png')"
            title="Linkedin"
          >
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/spacecode.app/"
           target="_blank">
          <img
            width="50"
            alt="instagram"
            :data-src="require('@/assets/images/socials/instagram.png')"
            title="Instagram"
          >
        </a>
      </li>
      <li>
        <a href="https://www.tiktok.com/@spacecode.app"
           target="_blank">
          <img
            width="50"
            alt="tik tok"
            :data-src="require('@/assets/images/socials/tiktok.png')"
            title="Tik Tok"
          >
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Socials',
  props: {
    showText: {
      type: Boolean
    }
  }
};
</script>
