<template>
  <div class="w-full flex justify-center mt-2">
    <img
      class="w-10"
      :data-src="require('@/assets/images/SpaceCodeGreenLogo.png')"
      alt="space_code_logo"
    >
  </div>
</template>
<script>
export default {
  name: 'SimpleTopBar'
};
</script>
