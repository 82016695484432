import { render, staticRenderFns } from "./BackToSpaceButton.vue?vue&type=template&id=3f00bdd9&scoped=true&"
import script from "./BackToSpaceButton.vue?vue&type=script&lang=js&"
export * from "./BackToSpaceButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f00bdd9",
  null
  
)

export default component.exports